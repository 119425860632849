
const Footer = () => {
  return (
    <div className="absolute z-10 bottom-0 right-0 text-center text-gray-light  text-xs p-5">
      Copyright @2023 MONTBLANC
    </div>
  );
}
 
export default Footer;
