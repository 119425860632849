import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const baseUrl = `${window.location.protocol}//${window.location.hostname}`
const apiUrl = `${baseUrl}/api`

export const useApi = () => {
	const token = useSelector(({ CSRFToken }) => CSRFToken);
	const { set } = useStore();

	const loadCSRFToken = () => {
		return fetch(`${baseUrl}/session/token`)
			.then(res => res.text())
			.then(freshToken => set('CSRFToken', freshToken))
	}

	const throwError = (message, cause) => {
		throw new Error(message, { cause: cause })
	}

	const fetchApi = async (uri, meth, csrf = false, data = false) => {
		return fetch(`${baseUrl}/api/${uri}`, {
			method: meth,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				...csrf && { 'X-CSRF-Token': token || await loadCSRFToken() }
			},
			...data && { body: JSON.stringify(data) },
		})
			.then(async (res) => {
				try {
					return [res, await res.json()]
				} catch (e) {
					return throwError('Response is not a valid JSON', 'Response is not a valid JSON')
				}
				
			})
			.then(([res, body]) => res.ok ? body : throwError(body.message || body, res))
	}

	return {
		get: async (uri, useCSRF = false) => await fetchApi(uri, 'GET', useCSRF),
		post: async (uri, data, useCSRF = false) => await fetchApi(uri, 'POST', useCSRF, data),
		patch: async (uri, data, useCSRF = false) => await fetchApi(uri, 'PATCH', useCSRF, data),
		remove: async (uri, useCSRF = false) => await fetchApi(uri, 'DELETE', useCSRF),
		del: async (uri, useCSRF = false) => await fetchApi(uri, 'DELETE', useCSRF),
	}
}

export const useStore = () => {
	const dispatch = useDispatch();
	return {
		set: (path, value) => {dispatch({ type: 'set',    path: path.split(':'), payload: value })},
		add: (path, value) => {dispatch({ type: 'add',    path: path.split(':'), payload: value })},
		remove: (path)     => {dispatch({ type: 'remove', path: path.split(':') })},
	}
}

const entityTypes = {
	user: {
		url: () => `user/data`,
		storePath: () => `user`,
		selector: (entities) => entities?.user,
		maxLifetime: 3600,
	},
	menus: {
		url: ({ uid }) => uid >= 0 && `menus/main,account,sub`,
		storePath: ({ uid }) => `menus:${uid}`,
		selector: (entities, props) => entities?.menus?.[props.uid],
		maxLifetime: 3600,
	},
	wishlist: {
		url: () => `user/wishlist`,
		storePath: () => `wishlist`,
		selector: (entities) => entities?.wishlist,
		preSave: (wishlist) => {
			const items = wishlist.reduce((entities, entitiy) => {
				return {...entities, [entitiy.id]: entitiy}
			}, {})
 
			return {'items': items || {}}
		},
		maxLifetime: 3600,
	},
	myCollection: {
		url: () => `user/collection`,
		storePath: () => `collection`,
		selector: (entities) => entities?.collection,
		preSave: (collection) => {
			const items = collection.reduce((entities, entitiy) => {
				return {...entities, [entitiy.id]: entitiy}
			}, {})
 
			return {'items': items || {}}
		},
		maxLifetime: 3600,
	},
	content: {
		url: ({ alias }) => alias && `content?alias=${alias}`,
		storePath: ({ alias }) => `nodes:${alias}`,
		selector: (entities, props) => entities?.nodes?.[props.alias],
		maxLifetime: 3600,
	},
	conceptList: {
		url: () => `concept/all`,
		storePath: () => `conceptList`,
		selector: (entities) => entities?.conceptList,
		maxLifetime: 3600,
	},
	concept: {
		url: ({ alias }) => alias && `content?alias=${alias}`,
		storePath: ({ alias }) => alias && `concepts:${alias}`,
		selector: (entities, props) => props.alias && entities?.concepts?.[props.alias],
		maxLifetime: 3600,
	},
	collection: {
		url: ({ alias }) => alias && `content?alias=${alias}`,
		storePath: ({ alias }) => `collections:${alias}`,
		selector: (entities, props) => entities?.collections?.[props.alias],
		maxLifetime: 3600,
	},
	product: {
		url: ({ alias }) => alias && `content?alias=${alias}`,
		storePath: ({ alias }) => alias && `products:${alias}`,
		selector: (entities, props) => props.alias && entities?.products?.[props.alias],
		maxLifetime: 3600,
	},
	search: {
		url: ({ string }) => string && `search?s=${string}`,
		storePath: ({ key }) => key && `search:${key}`,
		selector: (entities, props) => props.key && entities?.search?.[props.key],
		preSave: (entities) => {
			const reduce = (entities, type) => {
				return entities.reduce((entities, entitiy) => {
					if (entitiy.bundle[0].target_type !== type) return entities
					return [...entities, {
						id: 		entitiy.id[0].value,
						url: 		entitiy.path[0].alias,
						bundle: 	entitiy.bundle[0].value,
					}]
				}, [])
			}

			return {
				products: reduce(entities, 'mbt_product_type'),
				collections: reduce(entities, 'mbt_collection_type'),
			}
		},
		maxLifetime: 3600,
	},
}

let connectionList = []

export const useEntityLoader = (entityTypeId, props = {}) => {
	const { get } = useApi()
	const { set, add } = useStore()

	const entityType = entityTypes[entityTypeId]
	const entityApiUrl = entityType.url(props)
	const entityStorePath = entityType.storePath(props)

	const entity = useSelector(({ entities }) => {
		const entityCache = entityType.selector(entities, props)

		if (!entityCache) {
			return false;
		}

		else if (entityCache?.status) {
			switch (entityCache.status) {
				case 'loading':
					// console.log(entityCache, entityApiUrl)	
					break;
				case 'success':
					const lastTimeLoaded = Math.floor((Date.now() - entityCache.loadingStarted) / 1000)
					const maxLifetime = props.resetCache ? 0 : entityType.maxLifetime
					if (lastTimeLoaded > maxLifetime) {
						console.info('Cache maxLifetime reached!', lastTimeLoaded)
						return {
							...entityCache,
							needRebuild: true
						};
					}
					break;
				default:
					break;
			}
		}

		return entityCache
	})

	useEffect(() => {
		if (entity === false && entityStorePath) {
			const tempEntity = {
				status: 'loading',
				loadingStarted: Date.now()
			}

			add('entities:' + entityStorePath, tempEntity)
 
			if (entityApiUrl && !connectionList.includes(entityApiUrl)) {
				connectionList = [...connectionList, entityApiUrl]

				get(entityApiUrl, true)
					.then((loadedEntity) => {
						set('entities:' + entityStorePath, {
							...tempEntity,
							...(entityType.preSave ? entityType.preSave(loadedEntity, props) : loadedEntity),
							status: 'success',
							loadingSuccessed: Date.now()
						})
					})
					.catch(error => {
						set('entities:' + entityStorePath, {
							...tempEntity,
							...error,
							status: 'failed',
							loadingFailed: Date.now()
						})
					})
					.finally(() => connectionList = connectionList.filter((activeConnection) => activeConnection === entityApiUrl))
			}
		}
	}, [entity, entityType, entityStorePath, entityApiUrl, props, add, get, set])

	return entity;
}

export const useEntityManager = () => ({
	load: useEntityLoader
})


export const useAddWishlist = () => {
	const { post } = useApi()
	const { add } = useStore();
	return async (product, items = {}) => {
		return await post(`wishlist/add`, {'product': product.id}, true)
			.then(wishlistItem => {
				add(`entities:wishlist`, {items: {...items, [wishlistItem.id]: wishlistItem}})
			})
	}
}

export const useRemoveWishlist = () => {
	const { del } = useApi()
	const { remove } = useStore()
	return async (wishlistItem) => {
		await del(`wishlist/${wishlistItem.id}`, true)
			.then(() => {
				remove(`entities:wishlist:items:${wishlistItem.id}`)
			})
	}
}

export const useRemoveMyCollection = () => {
	const { del } = useApi()
	const { remove } = useStore()
	return async (myCollection) => {
		await del(`my-collection/${myCollection.id}`, true)
			.then(() => {
				remove(`entities:collection:items:${myCollection.id}`)
			})
	}
}

export async function getHeader(method = 'GET', useCSRF = false, data = false) {
	return {
		method: method,
		...data ? { body: JSON.stringify(data) } : null,
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			...useCSRF ? await getCSRFToken() : null
		}
	}
}

export async function getCSRFToken() {
	return {
		'X-CSRF-Token': await fetch(`${baseUrl}/session/token`).then(res => res.text())
	};
}


// export async function fetchJson(uri, useCSRF = false) {
// 	return await fetch(`${apiUrl}/${uri}`, await getHeader('GET', useCSRF))
// 		.then(async (res) => {
// 			if (!res.ok) {
// 				throw new Error(await res.json(), { cause: res });
// 			} else {
// 				return res.json()
// 			}
// 		})
// }

export async function postJson(uri, useCSRF = false, data = {}) {
	return await fetch(`${apiUrl}/${uri}`, await getHeader('POST', useCSRF, data))
		.then(async (res) => {
			if (!res.ok) {
				throw new Error(await res.json(), { cause: res });
			} else {
				return res.json()
			}
		})
}

export async function patchJson(uri, useCSRF = false, data = {}) {
	return await fetch(`${apiUrl}/${uri}`, await getHeader('PATCH', useCSRF, data))
		.then(async (res) => {
			if (!res.ok) {
				throw new Error(await res.json(), { cause: res });
			} else {
				return res.json()
			}
		})
}

// export async function deleteJson(uri, useCSRF = false) {
// 	return await fetch(`${apiUrl}/${uri}`, await getHeader('DELETE', useCSRF))
// 		.then(async (res) => {
// 			if (!res.ok) {
// 				throw new Error(await res.json(), { cause: res });
// 			} else {
// 				return res.json()
// 			}
// 		})
// }