import React, { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useStore } from './Helpers'
import Appbar from './Components/Appbar'
import Footer from './Components/Footer'
import LoadingScreen from './Components/LoadingScreen'

import './Main.scss';

const Home = lazy(() => import('./Screens/Home'))
const Concepts = lazy(() => import('./Screens/Entities/Concepts'))
const Concept = lazy(() => import('./Screens/Entities/Concept'))
const Collection = lazy(() => import('./Screens/Entities/Collection'))
const Product = lazy(() => import('./Screens/Entities/Product'))
const NodePage = lazy(() => import('./Screens/Node'))
const SearchPage = lazy(() => import('./Screens/SearchPage'))
const Concierge = lazy(() => import('./Screens/Concierge'))
const Account = lazy(() => import('./Screens/User/Account'))
const Login = lazy(() => import('./Screens/User/Login'))
const Logout = lazy(() => import('./Screens/User/Logout'))
const UserActivation = lazy(() => import('./Screens/User/UserActivation'))
const Register = lazy(() => import('./Screens/User/Register'))
const ResetPasswort = lazy(() => import('./Screens/User/ResetPasswort'))
const MyCollection = lazy(() => import('./Screens/MyCollection'))
const ModalMyCollection = lazy(() => import('./Components/modal/ModalMyCollection'))
const ModalWishlist = lazy(() => import('./Components/modal/ModalWishlist'))
const Wishlist = lazy(() => import('./Screens/Wishlist'))
const Error403 = lazy(() => import('./Screens/Errors/Error403'))
const Error404 = lazy(() => import('./Screens/Errors/Error404'))

const LoadingScreenActivtor = () => {
    const { add } = useStore();
    useEffect(() => add('loader', {show: true, bg: 'gray-main', text: 'white'}), [add])
    useEffect(() => () => add('loader', {show: false} ), [add]);
    return (<></>);
}

function App() {
    return (
        <Router>
            <div className='relative flex flex-col flex-auto min-h-full leading-tight tracking-wide'>
                <Appbar />
                <main className='screens flex flex-col flex-auto min-h-full pt-16 relative<'>
                    <LoadingScreen />
                    <Suspense fallback={<LoadingScreenActivtor />}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/search" element={<SearchPage />} />
                            <Route path="/contactus" element={<Concierge />} />
                            <Route path="/user" element={<Account />} />
                            <Route path="/user/register" element={<Register />} />
                            <Route path="/user/reset" element={<ResetPasswort />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/logout" element={<Logout />} />
                            <Route path="/user/:uid/activate/:hash" element={<UserActivation />} />
                            <Route path="/concepts" element={<Concepts />} />
                            <Route path="/concept/:conceptId" element={<Concept />} />
                            <Route path="/concept/:conceptId/:collectionId" element={<Collection />} />
                            <Route path="/concept/:conceptId/:collectionId/:productId" element={<Product />} />
                            <Route path="/my-collection" element={<MyCollection />} />
                            <Route path="/wishlist" element={<Wishlist />} />
                            <Route path="/403" element={<Error403 />} />
                            <Route path="/404" element={<Error404 />} />
                            <Route path="/*" element={<NodePage />} />
                            <Route path="*" element={<Error404 />} />
                        </Routes>
                        <ModalMyCollection />
                        <ModalWishlist />
                    </Suspense>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;