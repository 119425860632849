import { useSelector } from 'react-redux';
import LoadingSpinner from './LoadingSpinner'

const LoadingScreen = ({style = 'light'}) => {
  const { show } = useSelector(({ loader }) => loader);

  return  (
    <div className={`bg-gray-main inset-0 flex justify-center items-center absolute z-40 transition-all ease-in ${show ? 'fadeIn' : 'fadeOut'}`}>
      <div className={`text-black transition-colors`}>
        <div className='mb-10 text-gray-light'>Loading... </div>
        <LoadingSpinner style={style}/> 
      </div>
    </div>
  ) ;
}
 
export default LoadingScreen;