import { createStore } from 'redux';
import { fromJS } from 'immutable'

const initialState = {
  loader: {
    show: true,
    hidden: true,
    bg: 'white',
    text: 'black',
    delay: 500
  },
  user: {},
  countries:  {},
  appbar: {
    title: 'Loading...'
  },
  search: {
    string: '',
    items: [],
    products: [],
    collections: [],
    errors: false,
    show: 'products'
  },
  wishlist:  {},

  nodes:  {},
  concepts: {},
  conceptList: {},
  conceptsSelected: 0,
  collections:  {},
  products:  {},
  myCollection: {},
  menu: {
    account: false,
    main: false,
    sub: false,
  },
  // menuVisible: false,
  modal: {
    myCollection: {
      product: false,
      collection: false,
    },
    wishlist: false,
  },
  pages: {
    MyCollectionPage: {
      loaded: false
    }
  },
};


// const merge = (o, n) => {
//   // console.log(n)
//   Object.entries(n).forEach(([, i]) => {
//     o = { ...o, [+i.id]: { ...o[+i.id], ...i } }
//   });

//   return o;
// }

// const addItems = (o, n) => Object.keys(n).reduce((t, i) => (t[i] = { ...o[i], ...n[i] }, t), { ...o });



const reducer = (state = initialState, action) => {
  // console.log(action.type, action)
  // switch (action.type) {
  //   case 'SET_SEARCH_STRING':
  //     return { ...state, searchString: action.payload };
  //   case 'SET_SEARCH_ITEMS':
  //     return { ...state, searchItems: action.payload };

  //   case 'SET_APPBAR_TITLE':
  //     return { ...state, appbarTitle: action.payload };
  //   case 'SET_USER':
  //     return { ...state, user: action.payload };

  //   case 'SET_COUNTRIES':
  //     return { ...state, countries: action.payload };
  //   // case 'menuHide':
  //   //   console.log(state.menuVisible)
  //   //   return { ...state, menuVisible: false };
  //   // case 'menuShow':
  //   //   console.log(state.menuVisible)
  //   //   return { ...state, menuVisible: true };
  //   case 'concepts.set':
  //     return { ...state, concepts: { items: merge(state.concepts.items, action.concepts), loaded: true } };
  //   case 'concepts.add':
  //     return { ...state, concepts: { ...state.concepts, items: merge(state.concepts.items, action.concepts) }, };
  //   case 'collections.add':
  //     return { ...state, collections: { ...state.collections, items: merge(state.collections.items, action.collections) }, };
  //   case 'products.add':
  //     return { ...state, products: { ...state.products, items: merge(state.products.items, action.products) } };
  //   default:

  // }
  // const task = [ action.type, action.payload ]

  // console.log(task)


  if (!action.path || !action.path.length) {
    return state
  }
  // console.log((fromJS(state).deleteIn(action.path).toJS())?.entities?.wishlist)
  switch (action.type) {
    case 'remove':
      return { ...state, ...(fromJS(state).deleteIn(action.path).toJS()) }
    case 'add':
      return { ...state, ...(fromJS(state).mergeIn(action.path, fromJS(action.payload)).toJS()) }
    case 'set':
      return { ...state, ...(fromJS(state).setIn(action.path, fromJS(action.payload)).toJS()) }
    default:
      return state
  }

  // if (action.type === 'set') {
  //   const set = (paths, value, array = state) => {
  //     const path = paths.shift()
  //     return {
  //       ...array, ...{
  //         [path]:
  //         paths.length
  //           ? set(paths, value, array[path] ? array[path] : {})
  //           : value
  //       }
  //     }
  //   }
  //   // const payload = typeof action.payload === 'string' ? action.payload : action.payload
  //   // console.log("newState", action.path, action.payload)
  //   const newState = set(action.path, action.payload)

  //   // const newState = fromJS(state).setIn(action.path,  fromJS(action.payload)) .toJS()

  //   return { ...state, ...newState }
  // }

  // if (action.type === 'add') {
  //   const add = (paths, value, array = state) => {
  //     const path = paths.shift()
  //     return {
  //       ...array, ...{
  //         [path]:
  //         paths.length
  //           ? add(paths, value, array[path] ? array[path] : {})
  //           : { ...array[path], ...value }
  //       }
  //     }
  //   }

  //   const newState = add(action.path, action.payload)
  //   console.log("newState", action.path, action.payload, newState)
  //   return { ...state, ...newState }
  // }

  // // if (action.type === 'remove') {
  // //   const remove = (paths, array = state) => {
  // //     const path = paths.shift()
  // //     return (paths.length > 1)
  // //       ? { ...array, ...{ [path]: remove(paths, array[path] ? array[path] : {}) } }
  // //       : {}

  // //   }

  // //   function deleteProp(object, path) {
  // //     var last = path.pop();
  // //     delete path.reduce((o, k) => o[k] || {}, object)[last];
  // //   }
    
  // //   const newState = state
  // //   deleteProp(newState, action.path)
  // //   // const newState = remove(action.path)
  // //   return { ...state, ...newState}
  // // }



  // return state;
};

const store = createStore(reducer);

export default store;