import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { useEntityManager } from '../Helpers'
import logoImage from "../Assets/logo.png"

const Appbar = () => {
  const user = useEntityManager().load('user')
  const { main, account, sub } = useEntityManager().load('menus', user && {uid: user.uid})
  const { title } = useSelector(({ appbar }) => appbar)

  return (
    <div className='appbar relative z-50 before:fixed before:z-0 before:w-full before:h-16 before:bg-gray-main'>
      <div className='fixed w-full h-16 z-20 border-b border-gray-light text-white'>
        <div className='max-w-6xl px-5 h-full mx-auto flex items-center justify-between'>
          <Link className='w-16' to="/"><img src={logoImage} alt='Montblanc Treasure Room' /></Link>
          <div className='text-xl menu-title transition-opacity'>{ title }</div>
          <button className='menu-burgers w-16 h-16 flex items-center justify-center -mr-4' onClick={toggleMenu}>
            <span className={`absolute h-0.5 bg-white transition-all burger-line-1 w-8 -mt-5` /* w-6 rotate-45 */ }></span>
            <span className={`absolute h-0.5 bg-white transition-all burger-line-2 w-8` /* w-6 -rotate-45 */ }></span>
            <span className={`absolute h-0.5 bg-white transition-all burger-line-3 w-5 mr-3 -mb-5` /* w-0 */ }></span>
          </button>
        </div>
      </div>
      <div className={`menu flex max-sm:flex-col w-full h-full fixed inset-0 transition-all -translate-x-full`} id='menu'> 
        <div className='basis-gold max-sm:flex-1 pl-4xl-auto pt-48 max-sm:pt-28 max-sm:h-full box-content bg-gray-main relative z-50 '>  
          <div className='pl-5 text-5xl max-sm:text-3xl text-white flex flex-col gap-10 max-sm:gap-4'>
            { main && main.map((item, i) => <MenuItem item={item} className='' key={i} />) }
          </div>
        </div>
        <div className='basis-gold-small max-sm:flex-initial pr-4xl-auto box-content bg-gray-dark'>   
          <div className='flex flex-col max-sm:flex-row flex-wrap gap-20 max-sm:gap-2 h-full max-sm:h-auto pl-16 pt-48 pr-5 max-sm:p-5'>  
            <div className='text-2xl max-sm:text-xl text-white flex flex-col gap-5 max-sm:w-1/2'>
              { account && account.map((item, i) => <MenuItem item={item} className='' key={i} />) }
            </div>
            <div className='flex-1 flex flex-col gap-8 max-sm:gap-4 pr-40 max-sm:p-0 max-sm:w-1/2 text-sm max-sm:text-xs text-gray-light uppercase tracking-widest leading-normal'>
              { sub && sub.map((item, i) => <MenuItem item={item} className='' key={i} />) }
            </div>
            <div className='max-sm:w-full text-xs text-gray-light max-sm:pt-5'>
              Copyright @{new Date().getFullYear()} MONTBLANC
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const MenuItem = ({item, className}) => {  
  const location = useLocation()
  const [active, setActive] = useState(item?.uri === location.pathname);
  useEffect(() => {setActive(item.uri === location.pathname)}, [location, item.uri]);

  return (
    <Link onClick={toggleMenu} to={item.uri} className={`block ${className} ${active ? 'underline' : ''}`}>{ item.title }</Link>
  );
};

const toggleMenu = () => {
  const toggleClasses = (name, inactive, active = []) => {
    Array.from(document.getElementsByClassName(name)).map(({classList}) => {
      active.push('active')
      classList.contains('active') 
        ? active.map(c => classList.remove(c)) && inactive.map(c => classList.add(c))
        : inactive.map(c => classList.remove(c)) && active.map(c => classList.add(c))
      return true
    })
  }

  toggleClasses('menu', ['-translate-x-full'])
  toggleClasses('menu-title', ['opacity-100'], ['opacity-0'])
  toggleClasses('burger-line-1', ['w-8','-mt-5'       ], ['w-6','rotate-45' ])
  toggleClasses('burger-line-2', ['w-8'               ], ['w-6','-rotate-45'])
  toggleClasses('burger-line-3', ['w-5','mr-3','-mb-5'], ['w-0'             ])
}

export default Appbar;